<template>
<div class="usersList" >
  <div class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <div class="col-sm-6">
        <label for="">Поиск по названию</label>
        <template>
          <a-input placeholder="Название страны"
          v-model="countryName"
          @change="searchCountry"
          />
        </template>
      </div>
    </div>
  </div>
  <mini-loader v-if="load" />
  <div class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h5 class="text-dark">Страны</h5>
      <a @click="createCountryModalShow">
        <span class=" p-1">
          <button type="button" class="btn btn-success">Добавить
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 20 20">
              <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
            </svg>
          </button>

        </span>
      </a>
    </div>
      <table class="table table-striped" id="usersList">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Наименование</th>
            <th scope="col">Действие</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, index) in data">
            <th scope="row">{{index+1}}</th>
            <td>{{value.title}}</td>
            <td>
              <a  @click="showModal(value)">
                <span class="border rounded border-primary m-1 p-2 text-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                  </svg>
                </span>
              </a>
              <a @click="editModal(value)" v-if="value.id != 192">
                <span class="border rounded border-success m-1 p-2 text-success">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                  </svg>
                </span>
              </a>
              <a @click="deleteCountry(index, value)" v-if="value.id != 192">
                <span class="border rounded border-danger m-1 p-2 text-danger">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                  </svg>
                </span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="m-10 text-dark text-center" style="border: 1px solid #DFE1E4; padding: 10px">1 - {{data.length}} данных  из {{totalResults}}</p>
  </div>

<!-- Show Modal -->
<a-modal v-model="visible" title="Детали"  footer="">
  <table class="table table-striped" id="usersList">
    <tbody>
      <tr>
        <td>ID</td>
        <td>{{country.id}}</td>
      </tr>
      <tr>
        <td>Наименование</td>
        <td>{{country.title}}</td>
      </tr>
      <tr>
        <td>Количество регионов</td>
        <td v-if="country.regions">{{country.regions.length}}</td> <td v-else>0</td>
      </tr>
      <tr>
        <td>Количество городов</td>
        <td v-if="country.cities">{{country.cities.length}}</td> <td v-else>0</td>
      </tr>
      <tr>
        <td>Количество Филиалов</td>
        <td v-if="country.branches">{{country.branches.length}}</td> <td v-else>0</td>
      </tr>
    </tbody>
  </table>
</a-modal>

<!-- Edit modal -->
<a-modal v-model="visibleEditModal" title="Изменить данные"  footer="">
  <div class="form-group">
    <label for="exampleInputEmail1">Наименование</label>
    <input type="email" class="form-control" v-model="country.title" :class="{'is-invalid': validateErrors.title}" placeholder="Наименование">
    <div class="invalid-feedback" v-for="value in validateErrors.title"> {{value}} </div>
  </div>
  <div class="d-flex">
    <button type="submit" class="ml-auto btn btn-primary" @click="editCountry">Изменить</button>
  </div>
</a-modal>

<!-- Create Modal -->
<a-modal v-model="visibleCreateModal" title="Добавить новую"  footer="">
  <div class="form-group">
    <label for="exampleInputEmail1">Наименование</label>
    <input type="email" class="form-control" v-model="newCountry.title" :class="{'is-invalid': validateErrors.title}" placeholder="Наименование">
    <div class="invalid-feedback" v-for="value in validateErrors.title"> {{value}} </div>
  </div>
  <div class="d-flex">
    <button type="submit" class="ml-auto btn btn-primary" @click="createCountry">Изменить</button>
  </div>
</a-modal>

<!-- Delete modal -->
<a-modal v-model="visibleDeleteModal" title="Подтверждение на удаление" ok-text="Удалить" cancel-text="Отменить" @ok="delItem">
  <h6 style="color: red">Точно хотите безвозвратно удалить ?</h6>
</a-modal>
</div>


</template>

<script>
import axios from 'axios'

const data = '';
export default {
  name : 'User',
  data() {
    return {
      countryName: '',
      country: [],
      newCountry: {
        title: ''
      },

      ind: -10,
      visible: false,
      visibleEditModal: false,
      visibleDeleteModal: false,
      visibleCreateModal: false,

      data,
      currentPage: 1,
      totalResults: 0,
      scrolled: false,
      old_height: 0,
      last_page: 1,
      load: true,
      validateErrors: []
    };
  },
  mounted: function(){
   axios.get('/international/countries').then((response)=>{
       this.data = response.data.data;
       this.totalResults = response.data.total;
       this.load = false;
       this.last_page = response.data.last_page;
    }).catch((error)=>{
      if(error.response.status===401){
          this.$router.push('/pages/login')
          this.load = false;
      }
    });


    window.onscroll = () => {
        let height = document.getElementById('usersList').clientHeight
        console.log(height);
        if ((window.innerHeight + document.documentElement.scrollTop) >= height) {
          if(this.old_height!=height){
            this.old_height = height;
            let limit = this.data.length/this.$store.state.paginateCount + 1;
            if(limit <= this.last_page){
              if(this.countryName.length < 2){
                this.load = true;
                axios.get('/international/countries', {params: {page: limit}}).then(response => {
                  if(response.status == 200){
                      this.totalResults = response.data.total;
                      this.data = this.data.concat(response.data.data);
                      this.load = false
                  }else if(response.status == 204){
                    this.$message.error(
                      'Нет соответствующих стран',
                      3
                    );
                  }
                }).catch(error => {
                  if(response.status == 204){
                    this.$message.error(
                      'Нет соответствующих стран',
                      3
                    );
                  }
                  this.load = false
                });
              }else if(this.countryName.length > 1){
                this.load = true;
                axios.get('/international/countries/autocomplete/search/with/paginate', {params: {query: this.countryName, page: limit}}).then(response => {
                  if(response.status == 200){
                      this.totalResults = response.data.total;
                      this.data = this.data.concat(response.data.data);
                      this.load = false
                  }else if(response.status == 204){
                    this.$message.error(
                      'Нет соответствующих стран',
                      3
                    );
                    this.load = false
                  }
                }).catch(error => {
                  if(response.status == 204){
                    this.$message.error(
                      'Нет соответствующих стран',
                      3
                    );
                    this.load = false
                  }
                  this.load = false
                });
              }

            }
          }
        }
    };

  },
  methods: {
    searchCountry(value){
      if(this.countryName.length>1){
        this.load = true;
        axios.get('/international/countries/autocomplete/search/with/paginate', {params: {query: this.countryName }}).then(response => {
          if(response.status == 200){console.log(response)
            this.data = response.data.data;
            this.totalResults = response.data.total;
            this.load = false;
            this.last_page = response.data.last_page;
          }
        }).catch(error => {
          if(error.response.status == 204){
            this.$message.error(
                  'Нет соответствующих стран',
                  3,
            );
          }
        })
      }else if(this.countryName.length == 0){
        this.load = true;
        axios.get('/international/countries').then((response)=>{
            this.data = response.data.data;
            this.totalResults = response.data.total;
            this.load = false;
            this.last_page = response.data.last_page;
         }).catch((error)=>{
           if(error.response.status===401){
               this.$router.push('/pages/login')
               this.load = false;
           }
         });
      }
    },
    showModal(val) {
      this.country = val;
      this.visible = true;
    },
    handleOk(e) {
      console.log(e);
      this.visible = false;
    },
    editModal(val){
      this.validateErrors = [];
      this.country = val;
      this.visibleEditModal = true;
    },
    closeEditModal(e){
      this.visibleEditModal = false
    },
    editCountry(){
      axios.put('/international/countries/'+this.country.id, this.country).then(response => {
        if(response.status==200){
          this.visibleEditModal = false
          this.$message.success(
                'Успешно изменено',
                3,
          );

        }
      }).catch(error => {
        if(error.response.status == 422){
          this.validateErrors = error.response.data.errors
        }
      });
    },
    deleteCountry(ind, val){
      this.country = val;
      this.ind = ind;
      this.visibleDeleteModal = true;
    },
    delItem(){
      this.visibleDeleteModal = false;
      axios.delete('/international/countries/'+this.country.id).then(response => {
         if(response.status==200){
          this.visibleEditModal = false
          this.$message.success(
                response.data.success,
                3,
          );
          this.data.splice(this.ind, 1)
          this.totalResults--;
        }
      }).catch(error => {
        if(error.response.status==400){
          this.visibleEditModal = false
          this.$message.error(
                error.response.data.error,
                3,
          );
        }else if(error.response.status == 404){
          this.visibleEditModal = false
          this.$message.error(
                error.response.data.error,
                3,
          );
        }
      });
    },
    createCountryModalShow(){
      this.visibleCreateModal = true;
      this.validateErrors = [];
    },
    createCountry(){
      axios.post('/international/countries', this.newCountry).then(response => {
        if(response.status==201){
          this.visibleCreateModal = false
          // this.data = this.data.concat(response.data)
          this.newCountry.title = ''
          this.$message.success(
                'Успешно добавлено',
                3,
          );

        }
        console.log(response.data)
      }).catch(error => {
        if(error.response.status == 422){
          this.validateErrors = error.response.data.errors
        }
      });
    },
    removebyProperty(prop, val, multiple){
        for(var i= 0, L= this.length;i<L;i++){
            if(i in this && this[i][prop]=== val){
                this.splice(i, 1);
                if(!multiple) i= L;
            }
        }
        return this.length;
    }
  },
}
</script>
<style>
  .ant-modal-mask{
    z-index: 2000 !important;
  }
  .ant-modal-wrap{
    z-index: 2000 !important;
  }
  .ant-message{
    z-index: 2010 !important
  }
  .ant-message-notice{
    z-index: 3000 !important;
  }
  .ant-message-notice-content{
    z-index: 3000 !important;
  }
</style>
